import React from "react";
import Layout from "../components/layout/index";
import { Grid, useMediaQuery } from "@material-ui/core";
import { graphql, Link } from "gatsby";
// import ReactMarkdown from "react-markdown"
// import { STRAPI_API_URL } from "../utils/constants"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { PICTURE_STRAPI_API_URL } from "../utils/constants";
import parse, { domToReact } from "html-react-parser";
import Moment from "react-moment";

export default function Privacy({ data }) {
  const { strapiCookiesPoilicy } = data;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  const seoData = {
    title: data?.strapiCookiesPoilicy?.seo_title,
    description: data?.strapiCookiesPoilicy?.seo_description,
    keyword: data?.strapiCookiesPoilicy?.seo_tags,
    url: "/cookies-policy"
  };
  return (
    <Layout page={"cookiesPolicy"} seoData={seoData}>
      <Grid container style={{ marginTop: isMobile ? "15%" : "5%" }}>
        <Grid item xs={12}>
          <h1
            className={classes.heading}
            style={{ marginBottom: "0px", fontSize: isMobile && "24px" }}
          >
            {strapiCookiesPoilicy?.Title}
          </h1>
        </Grid>
        <Grid item xs={12}>
          <h5 className={classes.updated_at} style={{ margin: "0px" }}>
            {" "}
            updated at{" "}
            {
              <Moment format="MMM, DD YYYY">
                {strapiCookiesPoilicy?.updated_at}
              </Moment>
            }
          </h5>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.description}
        style={{ fontSize: isMobile && "18px" }}
      >
        {/* <ReactMarkdown
          source={strapiCookiesPoilicy?.privacy}
          renderers={{ image: Image }}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${STRAPI_API_URL}${uri}`
          }
        /> */}

        <div className="ck-content">
          {strapiCookiesPoilicy?.Cookies &&
            parse(strapiCookiesPoilicy?.Cookies, {
              replace: (domNode) => {
                if (domNode.name && domNode.name === "img") {
                  return React.createElement("img", {
                    src: `${PICTURE_STRAPI_API_URL}${domNode?.attribs?.src}`,
                    alt: `${domNode?.attribs?.alt}`,
                    style: { paddingLeft: "5%" },
                  });
                }
                if (
                  domNode.name &&
                  domNode.name === "a" &&
                  !domNode?.attribs?.href?.includes("mailto:")
                ) {
                  return (
                    <Link
                      to={
                        domNode?.attribs?.href?.includes("https://")
                          ? domNode?.attribs?.href
                          : `https://${domNode?.attribs?.href}`
                      }
                      target="_blank"
                    >
                      {domToReact(domNode?.children, domNode?.options)}
                    </Link>
                  );
                }
                if (domNode.name && domNode.name === "oembed") {
                  let videoId = getId(domNode?.attribs?.url);

                  return (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: isMobile ? "200px" : "500px",
                      }}
                    >
                      <iframe
                        width="90%"
                        height={"100%"}
                        title="title"
                        src={`//www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </Grid>
                  );
                }
              },
            })}
        </div>
      </Grid>
    </Layout>
  );
}
const useStyles = makeStyles((theme) => ({
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 100,
    fontSize: "22px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    textAlign: "justify",
    color: "#3F3F3F",
    padding: "14px 35px",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    textAlign: "center",
    fontSize: "32px",
  },
  updated_at: {
    fontFamily: theme.palette.fontFamily.Reef,
    textAlign: "center",
  },
}));
export const query = graphql`
  {
    strapiCookiesPoilicy {
      Title
      Cookies
      updated_at
      seo_tags
      seo_title
      seo_description
    }
  }
`;
